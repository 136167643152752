<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'pr-7 pl-7' : 'px-8'">

    <Snackbar ref="snackbar"/>

    <DialogAttachment 
      :dialog_attachment="dialog.attachment" 
      :state="process.state" 
      @input="data_attachment" 
      @file_response="response_attachment" 
      @close="closeDialog"/>

    <div class="mt-5">
      <div class="title black--text mb-2">Create Company</div>

      <Breadcrumbs :items="breadcrumbs"/>
    </div>

    <v-card outlined class="rounded-lg mb-5">
      <v-card-text>
        <ValidationObserver ref="observer">
          <v-form>
            <v-stepper v-model="step" alt-labels class="mx-0" flat>
              <v-stepper-header>
                
                <v-stepper-step :complete="step > 1" step="1">
                  Profile
                </v-stepper-step>
              
                <v-divider></v-divider>
  
                <v-stepper-step :complete="step > 2" step="2">
                  Setting
                </v-stepper-step>
  
                <v-divider></v-divider>
                <v-stepper-step :complete="step > 3" step="3">
                  Setting Certificate
                </v-stepper-step>
  
                <v-divider></v-divider>
                <v-stepper-step :complete="step > 4" step="4">
                  Notification Template
                </v-stepper-step>
  
                <v-divider></v-divider>
                <v-stepper-step step="5">
                  Contact
                </v-stepper-step>
              </v-stepper-header>
  
              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-row>
                    <v-col>
                      <ValidationProvider ref="Name" name="name" rules="required" v-slot="{ errors }">
                        <v-text-field
                          outlined
                          dense
                          flat
                          v-model="form.name"
                          :error-messages="errors"
                          placeholder="Name"
                          color="#F05326">
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>
                    <v-col>
                      <ValidationProvider ref="Phone Number" name="phone_number" rules="required|min:10|max:15" v-slot="{ errors }">
                        <v-text-field
                          outlined
                          dense
                          flat
                          v-model="form.phone_number"
                          :error-messages="errors"
                          placeholder="Phone Number"
                          color="#F05326">
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <ValidationProvider ref="Email" name="email" rules="required|email" v-slot="{ errors }">
                    <v-text-field
                      outlined
                      dense
                      flat
                      type="email"
                      v-model="form.email"
                      :error-messages="errors"
                      placeholder="Email"
                      color="#F05326">
                    </v-text-field>
                  </ValidationProvider>
                  <ValidationProvider ref="Description" name="description" rules="required" v-slot="{ errors }">
                    <v-textarea
                      outlined
                      dense
                      flat
                      v-model="form.description"
                      placeholder="Description"
                      rows="3"
                      color="#F05326">
                    </v-textarea>
                  </ValidationProvider>
                  <ValidationProvider ref="Name" name="short_description" rules="required" v-slot="{ errors }">
                    <v-textarea
                      outlined
                      dense
                      flat
                      v-model="form.short_description"
                      :error-messages="errors"
                      label="Short Description"
                      rows="3"
                      color="#F05326">
                    </v-textarea>
                  </ValidationProvider>
                  
                  <v-divider></v-divider>
                  <p class="text-h6">Company Address</p>
  
                  <div v-if="form.addresses != null && form.addresses.length > 0">
                    <v-row>
                      <v-col cols="6" v-for="(address, i) in form.addresses || []" :key="i">
                        <v-card outlined class="mb-3">
                          <v-card-title>
                            <span class="font-weight-bold">Address {{ i + 1 }}</span>
                            <v-btn
                              small
                              depressed
                              color="error"
                              class="ml-2"
                              @click="deleteAddress(i)">
                              Hapus
                            </v-btn>
                          </v-card-title>
                          <v-card-text class="py-0">
                            <v-row no-gutters>
                              <v-col cols="12">
                                <v-text-field
                                  outlined
                                  dense
                                  flat
                                  v-model="address.title"
                                  label="Title. Ex: Kantor Yogyakarta"
                                  color="#F05326">
                                </v-text-field>
                              </v-col>
                              <v-col cols="12">
                                <v-textarea
                                  outlined
                                  dense
                                  flat
                                  rows="2"
                                  v-model="address.address"
                                  label="Address"
                                  color="#F05326">
                                </v-textarea>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </div>
  
                  <v-btn 
                    small
                    depressed
                    color="primary"
                    class="my-3 text-capitalize"
                    @click="addAddress">
                    <v-icon left>mdi-plus</v-icon>
                    Add Address
                  </v-btn>
                </v-stepper-content>
  
                <v-stepper-content step="2" class="py-7 px-4">
                  <section class="mt-4">
                    <v-divider></v-divider>
                    <p class="text-h6">Appearance</p>
                    <v-text-field
                      placeholder="Ex: F05326"
                      label="Website Theme"
                      class="my-3"
                      hide-details
                      outlined
                      dense
                      flat
                      color="#F05326"
                      v-model="form.setting.appearance.theme_color">
                    </v-text-field>
                  </section>
  
                  <section class="mt-4">
                    <v-divider></v-divider>
                    <p class="text-h6">Company Configuration</p>
  
                    <v-row class="">
                      <v-col>
                        <v-card outlined class="px-4">
                          <div class="d-flex justify-space-between align-center">
                            <span> Enable Notifications</span>
                            <v-switch
                              v-model="form.setting.enable_notification"
                              flat
                              :label="form.setting.enable_notification ? 'Yes' : 'No'">
                            </v-switch>
                          </div>
                        </v-card>
                      </v-col>
                      <v-col>
                        <v-card outlined class="px-4">
                          <div class="d-flex justify-space-between align-center">
                            <span> Enable OTP Registration</span>
                            <v-switch
                              v-model="form.setting.enable_register_otp"
                              flat
                              :label="form.setting.enable_register_otp ? 'Yes' : 'No'">
                            </v-switch>
                          </div>
                        </v-card>
                      </v-col>
                      <v-col>
                        <v-card outlined class="px-4">
                          <div class="d-flex justify-space-between align-center">
                            <span> Enable User Profile Update <br/>(one time)</span>
                            <v-switch
                              v-model="form.setting.one_time_update_profile"
                              flat
                              :label="form.setting.one_time_update_profile ? 'Yes' : 'No'">
                            </v-switch>
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>
  
                    <v-row class="">
                      <v-col>
                        <v-card outlined class="px-4">
                          <div class="d-flex justify-space-between align-center">
                            <span> Enable Member Redeem Data Synchronization</span>
                            <v-switch
                              v-model="form.setting.enable_sync_data_member_redeem"
                              flat
                              :label="form.setting.enable_sync_data_member_redeem ? 'Yes' : 'No'">
                            </v-switch>
                          </div>
                        </v-card>
                      </v-col>
                      <v-col></v-col>
                      <v-col></v-col>
                    </v-row>
                  </section>
  
                  
                  <section class="mt-4">
                    <v-divider></v-divider>
                    <p class="text-h6">License Configuration</p>
                    <v-row>
                      <v-col>
                        <v-card outlined class="px-4">
                          <div class="d-flex justify-space-between align-center">
                            <span> License Prefix</span>
                            <v-switch
                              v-model="form.setting.license.prefix.enable"
                              flat
                              :label="form.setting.license.prefix.enable ? 'Yes' : 'No'">
                            </v-switch>
                          </div>
                        </v-card>
                      </v-col>
                      <v-col>
                        <v-expand-transition>
                          <v-card outlined class="px-4" v-show="form.setting.license.prefix.enable == true">
                            <div class="d-flex-col justify-center align-center">
                              <v-text-field
                                placeholder="Please Input License Prefix"
                                class="my-3"
                                hide-details
                                outlined
                                dense
                                flat
                                color="#F05326"
                                v-model="form.setting.license.prefix.value">
                              </v-text-field>
                            </div>
                          </v-card>
                        </v-expand-transition>
                      </v-col>
                    </v-row>
  
                    <v-row class="mb-4">
                      <v-col>
                        <v-card outlined class="px-4">
                          <div class="d-flex justify-space-between align-center">
                            <span> License surfix</span>
                            <v-switch
                              v-model="form.setting.license.surfix.enable"
                              flat
                              :label="form.setting.license.surfix.enable ? 'Yes' : 'No'">
                            </v-switch>
                          </div>
                        </v-card>
                      </v-col>
                      <v-col>
                        <v-expand-transition>
                          <v-card outlined class="px-4" v-show="form.setting.license.surfix.enable == true">
                            <div class="d-flex-col justify-center align-center">
                              <v-text-field
                                placeholder="Please Input License Surfix"
                                class="my-3"
                                hide-details
                                outlined
                                dense
                                flat
                                color="#F05326"
                                v-model="form.setting.license.surfix.value">
                              </v-text-field>
                            </div>
                          </v-card>
                        </v-expand-transition>
                      </v-col>
                    </v-row>
  
                  </section>
  
                  <section class="mt-4">
                    <v-divider></v-divider>
                    <p class="text-h6">Prakerja Configuration</p>
                    <v-row>
                      <v-col>
                        <v-card outlined class="px-4">
                          <div class="d-flex justify-space-between align-center">
                            <span> Using Prakerja</span>
                            <v-switch
                              v-model="form.setting.sync_redemption_prakerja"
                              flat
                              :label="form.setting.sync_redemption_prakerja ? 'Yes' : 'No'">
                            </v-switch>
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>
  
                    <v-expand-transition>
                      <div v-show="form.setting.sync_redemption_prakerja">
                        <v-row>
                          <v-col>
                            <v-text-field
                              outlined
                              dense
                              flat
                              v-model="form.setting.prakerja.callback_url"
                              label="Callback Prakerja URL"
                              placeholder="Ex: htpps://example.com/prakerja/callback"
                              rows="3"
                              color="#F05326">
                            </v-text-field>
                          </v-col>
                        </v-row>
      
                        <v-row>
                          <v-col>
                            <v-text-field
                              outlined
                              dense
                              flat
                              v-model="form.setting.prakerja.credential.client_code"
                              label="Prakerja Client Code"
                              placeholder="Please input Prakerja Client Code"
                              rows="3"
                              color="#F05326">
                            </v-text-field>
                          </v-col>
                          <v-col>
                            <v-text-field
                              outlined
                              dense
                              flat
                              v-model="form.setting.prakerja.credential.secret_key"
                              label="Prakerja Secret Key"
                              placeholder="Please input Prakerja Secret Key"
                              rows="3"
                              color="#F05326">
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </div>
                    </v-expand-transition>
  
                  </section>
                </v-stepper-content>
  
                <v-stepper-content step="3">
                  <v-row class="mb-4">
                    <v-col>
                      <v-card outlined class="px-4">
                        <div class="d-flex justify-space-between align-center">
                          <span> Competency Field Shown</span>
                          <v-switch
                            v-model="form.show_competence"
                            flat
                            :label="form.show_competence ? 'Yes' : 'No'">
                          </v-switch>
                        </div>
                      </v-card>
                    </v-col>
                  </v-row>
  
                  <v-row>
                    <v-col>
                      <v-text-field
                        outlined
                        dense
                        flat
                        v-model="form.participant.title"
                        label="Participant Title"
                        rows="3"
                        color="#F05326">
                      </v-text-field>
                      <v-text-field
                        outlined
                        dense
                        flat
                        v-model="form.participant.sub_title"
                        label="Participant Sub Title"
                        rows="3"
                        color="#F05326">
                      </v-text-field>
                      <v-text-field
                        outlined
                        dense
                        flat
                        v-model="form.graduation.title"
                        label="Graduation Title"
                        rows="3"
                        color="#F05326">
                      </v-text-field>
                      <v-text-field
                        outlined
                        dense
                        flat
                        v-model="form.graduation.sub_title"
                        label="Graduation Sub Title"
                        rows="3"
                        color="#F05326">
                      </v-text-field>
                    </v-col>
                  </v-row>
  
                  <v-row>
                    <v-col>
                      <ValidationProvider ref="Name" name="signature title" rules="required" v-slot="{ errors }">
                        <v-text-field
                          outlined
                          dense
                          flat
                          v-model="form.signature.title"
                          :error-messages="errors"
                          label="Signature Title"
                          placeholder="ex : Direktur"
                          rows="3"
                          color="#F05326">
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>
                    <v-col>
                      <ValidationProvider ref="Name" name="signature name" rules="required" v-slot="{ errors }">
                        <v-text-field
                          outlined
                          dense
                          flat
                          v-model="form.signature.name"
                          :error-messages="errors"
                          label="Signature Name"
                          placeholder="ex : John Doe"
                          rows="3"
                          color="#F05326">
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
  
                  <v-row>
                    <!-- hide signature -->
                    <div v-if="false" :class="['dropZone', 'cursor-pointer']" @click="dialog.attachment = true; process.state = 'signature'">
                      <div v-if="!filepreviewsignature" class="dropZone-info">
                        <span class="fa fa-cloud-upload dropZone-title"></span>
                        <div class="dropZone-upload-limit-info">
                          <div class="text-center mt-1">
                            <v-btn width="150" depressed dense @click="dialog.attachment = true; process.state = 'signature'">
                              Browse File
                            </v-btn>
                          </div>
                        </div>
                      </div>
                      <div v-else class="dropZone-info">
                        <span class="fa fa-cloud-upload dropZone-title"></span>
                        <v-img
                          aspect-ratio="7"
                          contain
                          class="mx-auto"
                          :src="filepreviewsignature">
                        </v-img>
                      </div>
                    </div>
                  </v-row>
                </v-stepper-content>
  
                <v-stepper-content step="4">
                  <div>
                    <v-tabs v-model="tab" class="ml-4">
                      <v-tab
                        active-class="active-tab"
                        v-for="item in ['Whatsapp', 'Inbox']"
                        :key="item"
                      >
                        {{ item }}
                      </v-tab>
                    </v-tabs>
                    <v-divider></v-divider>
                    <v-tabs-items v-model="tab">
                      <v-tab-item>
                        <v-row class="mt-2">
                          <v-col cols="12">
                            <v-textarea
                              outlined
                              dense
                              flat
                              v-model="form.setting.notification_template.whatsapp.registration"
                              label="Registration Whatsapp Template"
                              placeholder="Please input registration whatsapp template"
                              rows="8"
                              color="#F05326">
                            </v-textarea>
                          </v-col>
                          <v-col cols="12">
                            <v-textarea
                              outlined
                              dense
                              flat
                              v-model="form.setting.notification_template.whatsapp.request_change_password"
                              label="Request Change Password Whatsapp Template"
                              placeholder="Please input request change password whatsapp template"
                              rows="8"
                              color="#F05326">
                            </v-textarea>
                          </v-col>
                        </v-row>
                      </v-tab-item>
                      <v-tab-item>
                        <v-textarea
                          outlined
                          dense
                          flat
                          v-model="form.setting.notification_template.inbox.program_completed"
                          label="Program Completed Inbox Template"
                          placeholder="Please input program completed inbox template"
                          rows="8"
                          class="mt-5"
                          color="#F05326">
                        </v-textarea>
                      </v-tab-item>
                    </v-tabs-items>
                  </div>
                </v-stepper-content>
  
                <v-stepper-content step="5">
                  <v-row class="mb-3">
                    <v-col cols="4" v-for="(contact, i) in Object.keys(form.contact)" :key="i">
                      <v-card outlined class="pa-4">
                        <p class="text-h6 text-capitalize">{{ contact }}</p>
                        <v-text-field
                        outlined
                        dense
                        flat
                        color="primary"
                        :hint="`Ex: ${form.contact[contact].example}`"
                        persistent-hint
                        v-model="form.contact[contact].link"
                        :label="`Link ${contact}`"
                        :placeholder="`Please Input Link ${contact}`">
                        </v-text-field>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-stepper-content>
  
                <div class="ma-4 float-right">
                  <v-btn 
                    class="mr-4"
                    depressed
                    @click="() => {
                      if (step == 1) return $router.push('/company')
                      step = step-1
                    }">
                    {{ step == 1 ? 'Cancel' : 'Back' }}
                  </v-btn>
                  <v-btn
                    depressed
                    color="primary"
                    @click="() => {
                      if (step == steps.length) return save()
                      step = step+1
                    }"
                  >
                    {{ step == steps.length ? 'Save' : 'Next' }}
                  </v-btn>
                </div>
              </v-stepper-items>
            </v-stepper>
          </v-form>
        </ValidationObserver>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { get, post, put , destroy, upload } from '@/service/Axios'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Snackbar from '@/components/Snackbar.vue'
import DialogAttachment from './DialogAttacment.vue'

export default {
  components: { Breadcrumbs, Snackbar, DialogAttachment },
  data() {
    return {
      step: 1,
      tab: null,
      steps: [
        'Profile',
        'Setting Certificate',
        'Setting Certificate',
        'Notification Template',
        'Contacts'
      ],
      breadcrumbs: [
        {
          text: 'List Company',
          disabled: false,
          href: '/company',
        },
        {
          text: 'Create Company',
          disabled: true,
          href: '/company/create-company',
        }
      ],
      items:{},
      member: {},
      dialog: {
        attachment: false,
      },
      // Upload logo
      dragging: false,
      filelogo: [],
      filepreviewlogo: '',
      fileUploadlogo: [],
      // upload signature
      // dragging: false,
      filesignature: [],
      filepreviewsignature: '',
      fileUploadsignature: [],
      form: {
        name: "",
        description: "",
        short_description: "",
        email: "",
        phone_number: "",
        member_id: "",
        participant:{
          title: 'Sertifikat Kepesertaan',
          sub_title: 'Diberikan atas keberhasilan mengikuti kelas {{program_name}}',
        },
        graduation:{
          title: 'Sertifikat Kelulusan',
          sub_title: 'Diberikan atas keberhasilan mengikuti dan lulus dari kelas {{program_name}}',
        },
        signature:{
          title: '',
          name: '',
          image: ''
        },
        setting:{
          firebase: {},
          appearance: {
            theme_color: '',
          },
          export: {
            member_redeem: [],
            list_user_reseller: [],
            member_redeem_quiz: [],
            presence: []
          },
          license:{
            prefix: {
              enable: false,
              value: '',
            },
            surfix: {
              enable: false,
              value: '',
            }
          },
          notification_template: {
            whatsapp: {
              registration: `Ini adalah layanan pesan otomatis dari YEC CO ID. Jangan membalas pesan ini.\n\nKode OTP Anda: {{otp_code}}.\n\nJangan memberitau kode OTP ini kesiapapun, Waspada terhadap kasus penipuan. Jika terjadi kendala silahkan hubungi\nhttps://bit.ly/whatsappadminyec`,
              request_change_password: `Ini adalah layanan pesan otomatis dari YEC.CO.ID\n\nKode OTP Anda:\n{{otp_code}}\n\nJangan memberitahukan kode OTP ini kepada siapapun. Waspada terhadap kasus penipuan\n\nJika terjadi kendala, silakan hubungi CS melalui chat dengan klik link di bawah ini:\nhttps://bit.ly/CSBakatYEC`
            },
            inbox: {
              program_completed: `Halo {{fullname}},<br>Terima kasih telah mempercayakan dan menyelesaikan pelatihan kelas {{program_name}} di YEC.CO.ID. Sertifikat pelatihan dapat dilihat <a href='{{certificate_participant_url}}'>disini</a><br><br>Semoga pelatihan yang diambil, membantu Anda untuk meningkatkan keahlian dan kemampuan dalam terjun di dunia kerja.<br><br>Salam,<br>YEC.CO.ID<br>PREPARING FOR BETTER YOU`,
            }
          },
          sync_redemption_prakerja: false,
          prakerja: {
            callback_url: '',
            credential: {
              client_code: '',
              secret_key: ''
            }
          },
        },
        additional_fields: {
          members: [],
          member_redeems: [],
          resellers: []
        },
        show_competence: false,
        logo: "",
        favicon: "",
        sliders: [],
        addresses: [],
        contact: {
          email: {
            link: '',
            example: 'example@gmail.com'
          },
          facebook: {
            link: '',
            example: 'https://www.facebook.com/facebookname'
          },
          whatsapp: {
            link: '',
            example: 'https://wa.me/6281234567890'
          },
          instagram: {
            link: '',
            example: 'https://www.instagram.com/instagramname'
          },
          telegram: {
            link: '',
            example: 'https://t.me/telegramname'
          },
        }
      },
      process: {
        run: false,
        state: ''
      },
    }
  },
  created(){
  },
  mounted(){},
  methods:{
    addSlider(){
      this.form.sliders.push({
        link: '',
        slider_id: '',
        filepreview: ''
      })
    },
    deleteSlider(index){
      this.form.sliders.splice(index, 1)
    },
    addAddress() {
      this.form.addresses.push({
        title: "",
        address: "",
      })
    },
    deleteAddress(index) {
      this.form.addresses.splice(index, 1)
    },
    validation() {
      let _self = this
      
      if (_self.form.name == "") {
        _self.$refs.snackbar.open("#D32F2F", `Please fill in the company name`);
        _self.step = 1
        return false
      }

      if (_self.form.description == "") {
        _self.$refs.snackbar.open("#D32F2F", `Please fill in the company description`);
        _self.step = 1
        return false
      }

      if (_self.form.email == "") {
        _self.$refs.snackbar.open("#D32F2F", `Please fill in the company email`);
        _self.step = 1
        return false
      }

      if (_self.form.phone_number == "") {
        _self.$refs.snackbar.open("#D32F2F", `Please fill in the company phone number`);
        _self.step = 1
        return false
      }

      // addresses validation
      for (let i = 0; i < _self.form.addresses.length; i++) {
        if (_self.form.addresses[i].title == "") {
          _self.$refs.snackbar.open("#D32F2F", `Please fill in the address title`);
          _self.step = 1
          return false
        }

        if (_self.form.addresses[i].address == "") {
          _self.$refs.snackbar.open("#D32F2F", `Please fill in the address`);
          _self.step = 1
          return false
        }
      }

      if(_self.form.participant.title == "") {
        _self.$refs.snackbar.open("#D32F2F", `Please fill in the participant title`);
        _self.step = 3
        return false
      }

      if(_self.form.participant.sub_title == "") {
        _self.$refs.snackbar.open("#D32F2F", `Please fill in the participant sub title`);
        _self.step = 3
        return false
      }

      if(_self.form.graduation.title == "") {
        _self.$refs.snackbar.open("#D32F2F", `Please fill in the graduation title`);
        _self.step = 3
        return false
      }

      if(_self.form.graduation.sub_title == "") {
        _self.$refs.snackbar.open("#D32F2F", `Please fill in the graduation sub title`);
        _self.step = 3
        return false
      }

      if(_self.form.signature.title == "") {
        _self.$refs.snackbar.open("#D32F2F", `Please fill in the signature title`);
        _self.step = 3
        return false
      }

      if(_self.form.signature.name == "") {
        _self.$refs.snackbar.open("#D32F2F", `Please fill in the signature name`);
        _self.step = 3
        return false
      }

      return true
    },
    save() {
      let _self = this;
      let isValid = this.$refs.observer.validate()
      if (isValid && this.validation()) {
        _self.process.run = true
        post(`company/v1/superuser/create`,{
          data : this.form
        }).then(response => {
          let res = response.data
          if (res.status) {
            this.$refs.snackbar.open("#4CAF50", `Berhasil Menambahkan Company`);
            setTimeout(() => {
              this.$router.push('/company')
              },1000);
          } else {
            this.$refs.snackbar.open("#D32F2F", `Gagal Menambahkan Company`);
          }
          this.process.run = false
        }).catch(error => {
          this.$refs.snackbar.open("#D32F2F", `Gagal Menambahkan Company`);
          this.process.run = false
        })
      }
    },
    data_attachment(data){
      console.log(data);
    },
    response_attachment(res) {
      // console.log('res',res);

      let status = res.status.split('-')[0]
      let index = res.status.split('-')[1]

      // console.log('status',status);
      // console.log('index',index);

      if(status == "logo") {
        this.form.logo = res._id
        this.filepreviewlogo = res.media.public_url
      } 
      
      if(status == "signature") {
        this.form.signature.image = res._id
        this.filepreviewsignature = res.media.public_url
      }

      if(status == "slide") {
        this.form.sliders[index].slider_id = res._id
        this.form.sliders[index].filepreview = res.media.public_url
      }
    },
    closeDialog () {
      this.dialog.attachment = false
    },
  }
}
</script>

<style>
.v-stepper__label{
  text-align: center !important;
}
.dropZone {
  width: 100%;
  min-height: 300px;
  height: auto;
  position: relative;
  border: 3px dashed #ddd;
}

.dropZone:hover {
  border: 2px solid #F05326;
}

.dropZone:hover .dropZone-title {
  color: #F05326;
}

.dropZone-info {
  color: #A8A8A8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.dropZone-title {
  color: #787878;
}

.dropZone input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dropZone-upload-limit-info {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.dropZone-over {
  background: #ddd;
  opacity: 0.8;
}

.dropZone-uploaded {
  width: 100%;
  height: 200px;
  position: relative;
  border: 2px dashed #eee;
}

.dropZone-uploaded-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #A8A8A8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.removeFile {
  width: 200px;
}

.active-tab {
  color: white !important;
  background-color: #F05326 !important;
  border-radius:  10px 10px 0 0;
  transition: ease .1s;
}
</style>